import { useState } from "react";
import Input from "../components/Input/Input";

import "./Contact.scss";
import { ContactInformation } from "../../../shared/types";
import { ContactActions } from "../actions/contact";

const Contact = () => {
  const [information, setInformation] = useState<ContactInformation>({
    name: "",
    email: "",
    phone: "",
    message: "",
    suscribe: false,
  });

  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setSending(true);
      await ContactActions.post(information);
      setSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="flex-wrapper">
          <div className="input-container">
            {!sent && (
              <>
                <Input
                  type="text"
                  label="* Apellido, Nombre"
                  onChange={(e) => {
                    setInformation({ ...information, name: e });
                  }}
                  required={true}
                />
                <Input
                  type="text"
                  label="* Email"
                  onChange={(e) => {
                    setInformation({ ...information, email: e });
                  }}
                  required={true}
                />
                <Input
                  type="text"
                  label="* Teléfono"
                  onChange={(e) => {
                    setInformation({ ...information, phone: e });
                  }}
                  required={true}
                />
                <textarea
                  onChange={(e) => {
                    setInformation({ ...information, message: e.target.value });
                  }}
                  name=""
                  id=""
                  cols={30}
                  rows={4}
                ></textarea>
              </>
            )}
            {sent && <p>Email enviado</p>}
          </div>
          <div className="address-container">
            <h4>KARINA ZARFINO</h4>
            <address>
              <span>
                Teléfono:
                <a href="tel:+5491138522023"> +54 911 38522023</a>
              </span>
              <span>Whatsapp: +54 9 11 3822023</span>
              <span>CABA</span>
              <span>Buenos Aires</span>
              <span>Argentina</span>
            </address>
          </div>
          <img
            src="https://www.karinazarfino.com/datos/uploads/mod_diseno/thumbnail_log3kazar-61def6cb7fedf.png?t=1642002123" //This logo should be a .PNG with no background. I tried the static/logo but is a jpeg with white background.
            alt="Karina Zarfino logo"
          />
        </div>
        {!sent && (
          <label className="suscribe-label" htmlFor="">
            <input
              className="suscribe-check"
              type="checkbox"
              onChange={(e) => {
                setInformation({ ...information, suscribe: e.target.checked });
              }}
            />
            Quiero recibir novedades del sitio via e-mail.
          </label>
        )}
        {!sent && (
          <>
            <button
              className="form-button"
              style={{ display: "block" }}
              type="submit"
              disabled={sending}
            >
              {sending ? "Enviando..." : "Enviar"}
            </button>
            <p className="form-disclaimer">(*) Indica campos requeridos.</p>
          </>
        )}
      </form>
    </>
  );
};

export default Contact;
