export interface NavMenuItem {
  title: string;
  url?: string;
  submenu?: NavMenuItem[];
  enabled?: boolean;
}

export const getNavigationMenu = (loggedIn: boolean): NavMenuItem[] => [
  {
    title: "NOVEDADES",
    url: "/news",
  },
  {
    title: "SOBRE MÍ",
    url: "/about",
  },
  {
    title: "CURSOS",
    url: "/list/workshop",
  },
  {
    title: "T.E.S.A",
    url: "/list/tesa",
  },
  {
    title: "LIBROS",
    url: "/list/book",
  },
  {
    title: "RECONECTAR",
    submenu: [
      {
        title: "MEDITACIONES",
        url: "/list/meditation",
      },
      {
        title: "CANALIZACIONES",
        url: "/list/canalization",
      },
      {
        title: "PAREJAS",
        url: "/list/talks",
      },
      {
        title: "ENTREVISTAS",
        url: "/list/interviews",
      },
      {
        title: "PLANETA YO SOY",
        url: "/list/radio",
      },
    ],
  },
  {
    title: "BLOG",
    url: "/list/entry",
  },
  {
    title: "ACCESO ALUMNOS",
    url: loggedIn ? "/myworkshops" : "/login",
  },
  {
    title: "CONTACTO",
    url: "/contact",
  },
];
