import { ContactInformation } from "../../../shared/types";
import { post } from "./_base";

const path = "contact";

export class ContactActions {
  static post = async (data: ContactInformation) => {
    return await post(path, data);
  };
}
