import ReactPixel from "react-facebook-pixel";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./providers/AppContextProvider";
import { ErrorContextProvider } from "./providers/ErrorContextProvider";
import BasePage from "./pages/_BasePage";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Recover from "./pages/Recover";
import News from "./pages/News";
import List from "./pages/List";
import Details from "./pages/Details";
import User from "./pages/User";
import Photos from "./pages/Photos";
import Videos from "./pages/Videos";
import ClearCart from "./pages/ClearCart";
import { Cart } from "./pages/Cart";
import BuyDetails from "./pages/BuyDetails";
import Workshop from "./pages/Workshop";
import Register from "./pages/Register";
import MyWorkshops from "./pages/MyWorkshops";
import MyWorkshop from "./pages/MyWorkshop";
import AboutMe from "./pages/AboutMe";
import GetOut from "./pages/GetOut";
import PayTest from "./pages/PayTest";
import Pay from "./pages/Pay";
import BackBasePage from "./_backend/pages/_BasePage";
import BackendMain from "./_backend/pages/Main";
import BackendWorkshop from "./_backend/pages/WorkshopPage";
import BackendWorkshops from "./_backend/pages/WorkshopsPage";
import BackendBlog from "./_backend/pages/BlogPage";
import BackendBlogs from "./_backend/pages/BlogsPage";
import BackendCountry from "./_backend/pages/CountryPage";
import BackendCountries from "./_backend/pages/CountriesPage";
import BackendVideo from "./_backend/pages/VideoPage";
import BackendVideos from "./_backend/pages/VideosPage";
import BackendUsers from "./_backend/pages/UsersPage";
import BackendUser from "./_backend/pages/UserPage";
import BackendAboutMe from "./_backend/pages/AboutMePage";
import BackendCard from "./_backend/pages/CardPage";
import BackendCards from "./_backend/pages/CardsPage";
import BackendTickets from "./_backend/pages/TicketsPage";
import BackendPhotos from "./_backend/pages/PhotosPage";
import { AddressSelector } from "./pages/AddressSelector";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init("788475282327638", undefined, options);

function App() {
  return (
    <>
      <AppContextProvider>
        <ErrorContextProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/backend/about/"
                element={<BackBasePage component={BackendAboutMe} />}
              />
              <Route
                path="/backend/card/:type/:id"
                element={<BackBasePage component={BackendCard} />}
              />
              <Route
                path="/backend/cards/:type/"
                element={<BackBasePage component={BackendCards} />}
              />
              <Route
                path="/backend/card/:type/"
                element={<BackBasePage component={BackendCard} />}
              />
              <Route
                path="/backend/workshops/:type"
                element={<BackBasePage component={BackendWorkshops} />}
              />
              <Route
                path="/backend/newworkshop/:type"
                element={<BackBasePage component={BackendWorkshop} />}
              />
              <Route
                path="/backend/workshop/:id"
                element={<BackBasePage component={BackendWorkshop} />}
              />
              <Route
                path="/backend/blog/"
                element={<BackBasePage component={BackendBlogs} />}
              />
              <Route
                path="/backend/entry/"
                element={<BackBasePage component={BackendBlog} />}
              />
              <Route
                path="/backend/entry/:id"
                element={<BackBasePage component={BackendBlog} />}
              />
              <Route
                path="/backend/country/"
                element={<BackBasePage component={BackendCountry} />}
              />
              <Route
                path="/backend/country/:id"
                element={<BackBasePage component={BackendCountry} />}
              />
              <Route
                path="/backend/countries/"
                element={<BackBasePage component={BackendCountries} />}
              />
              <Route
                path="/backend/video/"
                element={<BackBasePage component={BackendVideo} />}
              />
              <Route
                path="/backend/video/:id"
                element={<BackBasePage component={BackendVideo} />}
              />
              <Route
                path="/backend/videos/"
                element={<BackBasePage component={BackendVideos} />}
              />
              <Route
                path="/backend/photos/"
                element={<BackBasePage component={BackendPhotos} />}
              />
              <Route
                path="/backend/tickets/"
                element={<BackBasePage component={BackendTickets} />}
              />
              <Route
                path="/backend/users/"
                element={<BackBasePage component={BackendUsers} />}
              />
              <Route
                path="/backend/user/:id"
                element={<BackBasePage component={BackendUser} />}
              />
              <Route
                path="/backend/*"
                element={<BackBasePage component={BackendMain} />}
              />
              <Route
                path="/list/:type"
                element={<BasePage component={List} />}
              />
              <Route
                path="/details/:type/:id"
                element={<BasePage component={Details} />}
              />
              <Route
                path="/workshop/:id"
                element={<BasePage component={Workshop} />}
              />
              <Route path="/login" element={<BasePage component={Login} />} />
              <Route
                path="/recover"
                element={<BasePage component={Recover} />}
              />

              <Route
                path="/register"
                element={<BasePage component={Register} />}
              />
              <Route
                path="/contact"
                element={<BasePage component={Contact} />}
              />
              <Route path="/photos" element={<BasePage component={Photos} />} />
              <Route path="/videos" element={<BasePage component={Videos} />} />
              <Route
                path="/user"
                element={<BasePage loginRequired={true} component={User} />}
              />
              <Route
                path="/buydetails"
                element={<BasePage component={BuyDetails} />}
              />
              <Route
                path="/addressselector"
                element={<BasePage component={AddressSelector} />}
              />
              <Route
                path="/myworkshops"
                element={
                  <BasePage loginRequired={true} component={MyWorkshops} />
                }
              />
              <Route
                path="/myworkshop/:id"
                element={
                  <BasePage loginRequired={true} component={MyWorkshop} />
                }
              />
              <Route path="/about" element={<BasePage component={AboutMe} />} />
              <Route
                path="/getOut"
                element={<BasePage loginRequired={true} component={GetOut} />}
              />
              <Route
                path="/payTest"
                element={<BasePage component={PayTest} />}
              />
              <Route path="/cart" element={<BasePage component={Cart} />} />

              <Route path="/pay" element={<BasePage component={Pay} />} />
              <Route
                path="/clearCart"
                element={<BasePage component={ClearCart} />}
              />
              <Route path="/news" element={<BasePage component={News} />} />
              <Route path="*" element={<BasePage component={AboutMe} />} />
            </Routes>
          </BrowserRouter>
        </ErrorContextProvider>
      </AppContextProvider>
    </>
  );
}

export default App;
