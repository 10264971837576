import { useContext, useEffect, useState } from "react";
import RedButton from "../components/Button/RedButton";
import ItemLong from "../components/Item/ItemLong";
import YoutubeEmbed from "../components/YoutubeEmbed";
import WhatsappBtn from "../components/WhatsappBtn/WhatsappBtn";
import "./News.scss";
import { ContentContext } from "../providers/ContentContextProvider";
import { AppContext } from "../providers/AppContextProvider";
import { News as NewsType } from "../../../shared/types";
import { SectionActions } from "../actions/sections";

const News = () => {
  const contentContext = useContext(ContentContext);

  const appContext = useContext(AppContext);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [news, setNews] = useState<NewsType>();

  const fetchData = async () => {
    const response = await SectionActions.get<NewsType>({
      sectionName: "News",
      country: appContext.location.code,
    });
    setNews(response.data);
  };

  return (
    <div className="home">
      <WhatsappBtn />
      <div className="home-container">
        {news?.text && (
          <div
            className="home-text"
            dangerouslySetInnerHTML={{
              __html: news.text ?? "",
            }}
          />
        )}
        <div className="home-talleres">
          {news?.workshops.map((wid) => {
            const w = contentContext.workshops.find((x) => x.id === wid);

            if (!w) return undefined;

            return (
              <ItemLong
                id={w.id ?? ""}
                type={w.type}
                to={`/workshop/${w.id}`}
                key={w.id}
                title={w.title ?? ""}
                description={w.description}
                thumb={w.image?.path}
              />
            );
          })}
        </div>
        <RedButton text="Ver todos" to="/workshops" />
        <div className="youtube-single">
          <YoutubeEmbed
            title="video"
            link={
              `//${news?.youtubeLink}` ?? "//www.youtube.com/embed/mm5KLOYeayY"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default News;
